"use client";

import { clsx } from "clsx";
import type { ReactNode } from "react";
import { Translation } from "@/components/Translation/Translation.component";
import type { Translations } from "@/components/Translation/translation.types";
import { useScrollDirection } from "@/hooks/use-scroll-direction.hook";
type ToolbarMenuDenseItemProps = {
  readonly label: Translations;
  readonly children: ReactNode;
};
export function ToolbarMenuDenseItem({
  label,
  children
}: ToolbarMenuDenseItemProps) {
  const scrollYDeltaThreshold = 16;
  const {
    isScrollingDown
  } = useScrollDirection({
    scrollYDeltaThreshold
  });
  return <>
      {children}
      <span className={clsx(isScrollingDown ? "h-0 overflow-hidden" : "h-2", "whitespace-nowrap text-sm transition-all motion-reduce:transition-none")}>
        <Translation model={label} data-sentry-element="Translation" data-sentry-source-file="ToolbarMenuDenseItem.component.tsx" />
      </span>
    </>;
}